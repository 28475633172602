.SocialIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    &:hover {
      opacity: 0.5;
    }
  }
  a.SocialIcon__withTitle {
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      margin-left: 10px;
    }
  }
}
