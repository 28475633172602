.SpinLoader {
  height: 10px;
  width: 10px;
  position: relative;
}

.ball-spin-fade-loader > div {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  margin: 1px;
  animation-fill-mode: both;
  position: absolute;
}

@keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-spin-fade-loader > div:nth-child(1) {
  top: 7px;
  left: 0;
  animation: ball-spin-fade-loader 1s -0.96s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(2) {
  top: 5px;
  left: 5px;
  animation: ball-spin-fade-loader 1s -0.84s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(3) {
  top: 0;
  left: 7px;
  animation: ball-spin-fade-loader 1s -0.72s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(4) {
  top: -5px;
  left: 5px;
  animation: ball-spin-fade-loader 1s -0.6s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(5) {
  top: -7px;
  left: 0;
  animation: ball-spin-fade-loader 1s -0.48s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(6) {
  top: -5px;
  left: -5px;
  animation: ball-spin-fade-loader 1s -0.36s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(7) {
  top: 0;
  left: -7px;
  -webkit-animation: ball-spin-fade-loader 1s -0.24s infinite linear;
  animation: ball-spin-fade-loader 1s -0.24s infinite linear;
}
.ball-spin-fade-loader > div:nth-child(8) {
  top: 5px;
  left: -5px;
  animation: ball-spin-fade-loader 1s -0.12s infinite linear;
}
