@import 'src/_mixins/vars';
@import 'src/_mixins/screen-size';

.DarkModeSwitcher {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .DarkModeSwitcher__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #383d51;
    border-radius: 25px;

    img {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: 50%;
    }
  }

  input:checked + .DarkModeSwitcher__slider img {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
}

.DarkModeSwitcher__active {
  .DarkModeSwitcher__slider {
    background-color: #f1f1f3;
  }
}
