$roboto-font: 'roboto-font', sans-serif;

// font size
$regular: 16px;
$extraLarge: 20px;
$large: 18px;
$medium: 14px;
$small: 12px;
$extraSmall: 10px;

// different
$borderRadius: 8px;
// $boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
$boxShadow: var(--card-box-shadow);
$transition: all 0.2s ease;

// colors
$secondary: #2ebac6;
$darkBlue: #383d51;
$lightGray: #e1e1e1;
$white: #f1f1f3;
$mainBg: #2b2d3c;