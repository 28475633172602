%base-animation-diagonal-move {
  animation: 3s ease-in-out 0s infinite normal none running;
}

.ssap-animation-diagonal-move, .ssap-animation-diagonal-move-left {
  @extend %base-animation-diagonal-move;
  animation-name: diagonal-move-left;
}
.ssap-animation-diagonal-move-right {
  @extend %base-animation-diagonal-move;
  animation-name: diagonal-move-right;
}
.ssap-animation-scale-in-out {
  @extend %base-animation-diagonal-move;
  animation-name: scale-in-out;
}
.ssap-animation-vertical {
  @extend %base-animation-diagonal-move;
  animation-name: vertical;
}


@keyframes diagonal-move-left {
  0% {
      transform: translate(0px, 0px);
  }
  50% {
      transform: translate(-5px, -5px);
  }
  100% {
      transform: translate(0px, 0px);
  }
}

.ssap-animation-popup {
  animation-name: popUp;
  animation-duration: 400ms;
  animation-play-state: running;
  animation-timing-function: cubic-bezier(0, .92, .9, 1.4);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &.-small {
    animation-timing-function: cubic-bezier(0, .8, .85, 1.1);
  }

  @keyframes popUp {
    0% {
      transform: scale(0.0);
    }
  
    100% {
      transform: scale(1.0);
    }
  }
}

@keyframes diagonal-move-right {
  0% {
      transform: translate(0px, 0px);
  }
  50% {
      transform: translate(-5px, 5px);
  }
  100% {
      transform: translate(0px, 0px);
  }
}

@keyframes vertical {
  0% {
      transform: translate(0px, 0px);
  }
  50% {
      transform: translate(0px, 5px);
  }
  100% {
      transform: translate(0px, 0px);
  }
}

@keyframes scale-in-out {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}