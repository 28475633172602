$scale: 0.85;

%rei-text-base {
    // font-family: Roboto;
    font-weight: 600;
    line-height: 150%;
}

%ksw2-text-base {
    font-family: Roboto;
    font-weight: 400;
    line-height: 150%;
}

.ssap-text-display-1 {
    @extend %rei-text-base;
    font-size: 80px;
}
.ssap-text-display-2 {
    @extend %rei-text-base;
    font-size: 72px;
}
.ssap-text-display-3 {
    @extend %rei-text-base;
    font-size: 64px;
}
.ssap-text-display-4 {
    @extend %rei-text-base;
    font-size: 56px;
}
.ssap-text-display-5 {
    @extend %rei-text-base;
    font-size: 48px;
}
.ssap-text-display-6 {
    @extend %rei-text-base;
    font-size: 40px;
}
.ssap-text-heading-1 {
    @extend %rei-text-base;
    font-size: 40px;
}
.ssap-text-heading-2 {
    @extend %rei-text-base;
    font-size: 32px;
}
.ssap-text-heading-3 {
    @extend %rei-text-base;
    font-size: 28px;
}
.ssap-text-heading-4 {
    @extend %rei-text-base;
    font-size: 24px;
}
.ssap-text-heading-5 {
    @extend %rei-text-base;
    font-size: 20px;
}
.ssap-text-heading-6 {
    @extend %rei-text-base;
    font-size: 16px;
}
.ssap-text, .ssap-text-paragraph, .ssap-text-paragraph-regular {
    @extend %rei-text-base;
    font-size: 16px;
    font-weight: normal;
}
.ssap-text-bold, .ssap-text-paragraph-bold {
    @extend %rei-text-base;
    font-size: 16px;
}
.ssap-text-caption, .ssap-text-caption-regular {
    @extend %rei-text-base;
    font-size: 14px;
    font-weight: normal;
}
.ssap-text-caption-bold {
    @extend %rei-text-base;
    font-size: 14px;
}
.ssap-text-button {
    @extend %rei-text-base;
    font-size: 16px;
}
.ssap-text-link {
    @extend %rei-text-base;
    font-size: 16px;
    font-weight: normal;
    text-decoration-line: underline;
}

.ssap-text-h1 {
    @extend %ksw2-text-base;
    font-weight: 300;
    font-size: #{$scale * 72}px;
}
.ssap-text-h2 {
    @extend %ksw2-text-base;
    font-weight: 300;
    font-size: #{$scale * 64}px;
}
.ssap-text-h3 {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 48}px;
}
.ssap-text-h4 {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 40}px;
}
.ssap-text-h5 {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 32}px;
}
.ssap-text-h6 {
    @extend %ksw2-text-base;
    font-weight: 500;
    font-size: #{$scale * 24}px;
}
.ssap-text-subtitle-1 {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 16}px;
}
.ssap-text-subtitle-2 {
    @extend %ksw2-text-base;
    font-weight: 500;
    font-size: #{$scale * 14}px;
}
.ssap-text-body-1 {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 16}px;
}
.ssap-text-body-2 {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 14}px;
}
.ssap-text-button-large {
    @extend %ksw2-text-base;
    font-weight: 500;
    font-size: #{$scale * 14}px;
    text-transform: uppercase;
}
.ssap-text-button-medium {
    @extend %ksw2-text-base;
    font-weight: 500;
    font-size: #{$scale * 15}px;
    text-transform: uppercase;
}
.ssap-text-button-small {
    @extend %ksw2-text-base;
    font-weight: 500;
    font-size: #{$scale * 14}px;
    text-transform: uppercase;
}
.ssap-text-caption {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 12}px;
}
.ssap-text-overline {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 12}px;
    text-transform: uppercase;
}
.ssap-text-avatar {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 20}px;
}
.ssap-text-input-label {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 12}px;
}
.ssap-text-input-helper {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 12}px;
}
.ssap-text-input-text {
    @extend %ksw2-text-base;
    font-weight: 400;
    font-size: #{$scale * 16}px;
}
.ssap-text-tooltip {
    @extend %ksw2-text-base;
    font-weight: 500;
    font-size: #{$scale * 10}px;
}