@import './_animation.scss';

.ssap-card {
    border: var(--card-border);
    box-shadow: var(--card-box-shadow);
    border-radius: var(--card-border-radius);
    background-color: var(--card-background-color);
    
    &.-light {
        background: var(--card-light-background);
    }
    
    &.-dark-gradient {
        border: var(--card-dark-gradient-border);
        background: var(--card-dark-gradient-background);
    }
    
    &.-dark-gradient2 {
        border: var(--card-dark-gradient-border);
        background: linear-gradient(180deg, #03007E 0%, #000E49 100%);
    }
}

.ssap-transparent-card {
    background: var(--transparent-card-background);
    box-shadow: var(--transparent-card-box-shadow);
    border: var(--transparent-card-border);
    border-radius: var(--transparent-card-border-radius);

    &.-aura {
        background: var(--transparent-card-aura-background);
        box-shadow: 10px 10px 30px var(--transparent-card-aura-shadow-color);
        border-radius: 16px;
        border: none;
        &.-white {
            background: var(--transparent-card-aura-white-background);
        }
    }
}

.ssap-ontop-transparent-card {
    background: var(--ontop-transparent-card-background);
    border-radius: var(--ontop-transparent-card-border-radius);

    &.-center {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}