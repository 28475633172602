@import 'src/_mixins/vars';
@import 'src/_mixins/screen-size';

.LabeledSwitch {
  border-radius: 1px;
  padding: 1px;
  position: relative;

  &__pointer {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    transition: all 0.4s ease;
    padding: 4px 2px;
    span {
      display: block;
      border-radius: 1px;
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__inner {
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-width: 1px;
    border-style: solid;
  }

  button {
    width: 50%;
    position: relative;
    z-index: 2;
    transition: all 0.4s ease;
    display: inline-block;
    padding: 1px 2px 2px;
    p {
      display: inline-block;
      margin: 0;
      span {
        display: block;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__buttonActive {
    p {
      span {
        font-weight: 600;
      }
    }
  }
}

.LabeledSwitchDisabled {
  button {
    cursor: default;
  }
}
