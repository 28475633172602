@import 'src/_mixins/vars';
@import 'src/_mixins/screen-size';

.TokenIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__image {
    margin-right: 10px;
    @include respond-to(sm) {
      margin-right: 7px;
    }
  }

  &__symbol {
    font-weight: 600;
    color: $secondary;
  }

  &__name,
  &__dollar {
    font-size: $large;
    color: $darkBlue;
    @include respond-to(xl) {
      font-size: $regular;
    }
    @include respond-to(lg) {
      font-size: $medium;
    }
    @include respond-to(md) {
      font-size: $regular;
    }
    @include respond-to(sm) {
      font-size: $small;
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    @include respond-to(xs) {
      max-width: 90px;
    }
    b {
      font-weight: 600;
    }
  }

  &__color-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: -8px;
  }
}

.TokenIconWithFullName {
  max-width: 100%;
  position: relative;
}

.TokenIcon__withSymbolAndName {
  .TokenIcon__name {
    b {
      font-weight: 300;
    }
  }
}

.TokenIcon__dark {
  .TokenIcon__name,
  .TokenIcon__dollar {
    color: $white;
  }
}

.TokenIcon__onWhiteBackground {
  .TokenIcon__name,
  .TokenIcon__dollar {
    color: $darkBlue;
  }
}
